@import "./constants.css";

/* GENERAL STYLES */
.alert {
	font-weight: 600;
	color: var(--greyThunder);
	padding: 10px;
	line-height: 1.2em;
}

.alert-danger {
	background-color: var(--redLight);
	border: 1px solid var(--red);
}

.alert-success {
	background-color: var(--greenPanache);
	border: 1px solid var(--greenAccessible);
}

.alert-warning {
	background-color: var(--amberLight);
	border: 1px solid var(--amberDark);
}

.btn {
	border-radius: 20px;
	padding: 0 15px;
	font-weight: 600;
	height: 40px;
	font-family: "HelveticaNow300normal";
}

.btn-md {
	width: 90%;
	height: 2.5rem;
	border-radius: 8rem;
	margin: 0 0 1rem 0;
	font-size: 0.95rem;
}

.btn-large {
	width: 20rem;
	height: 4rem;
	border-radius: 10rem;
	margin: 10px;
	font-family: "HelveticaNow300normal";
}

.btn-primary-green:disabled {
	background-color: var(--greyShuttle) !important;
	border: solid var(--greyShuttle) !important;
}

.btn-primary-green:active {
	background-color: var(--greenDarkFern) !important;
	border: solid var(--greenDarkFern) !important;
	border-width: 2;
	outline: var(--greenDarkFern) solid 2px !important;
	outline-offset: 3px !important;
}

.btn-primary-green {
	background-color: var(--greenAccessible);
	border: 1px solid var(--greenAccessible);
}

.btn-primary-green:hover {
	background-color: var(--greenDarkFern);
}

.btn-secondary-green {
	background-color: var(--transparent);
	color: var(--greenAccessible);
	border: 1px solid var(--greenAccessible);
}

.btn-secondary-green:hover,
input[type="file"]::file-selector-button:hover {
	border: solid var(--greenAccessible);
	border-width: 2;
	background-color: var(--transparent);
	color: var(--greenAccessible);
}

.btn-secondary-green:active,
input[type="file"]::file-selector-button:active {
	border: solid var(--greenAccessible) !important;
	border-width: 2 !important;
	background-color: var(--greenAccessible) !important;
	color: white !important;
	font-weight: bold !important;
}

.btn-text-only {
	border: 0;
	background-color: var(--transparent);
	color: var(--purpleTelus);
	font-family: "HelveticaNow400normal";
	font-size: 0.9rem;
}

.btn-text-only:disabled {
	border: 0 !important;
	background-color: var(--transparent) !important;
	color: var(--greyShuttle);
	pointer-events: none;
}

.btn-text-only:hover {
	border: 0;
	background-color: var(--transparent);
	color: var(--purpleTelus);
	text-decoration: underline;
}

.btn-text-only:active {
	border: 0;
	background-color: var(--transparent) !important;
	color: var(--purpleTelus) !important;
	text-decoration: underline;
}

#btn-lang {
	height: 24px;
}

.container {
	margin-top: 3rem;
	margin-bottom: 3rem;
}

input[type="file"]::file-selector-button {
	background-color: var(--transparent);
	color: var(--greenAccessible);
	border: 1px solid var(--greenAccessible);
	border-radius: 20px;
	padding: 0 15px;
	height: 30px;
	width: 120px;
}

.logo-vert {
	width: 250px;
	margin: auto;
}

.logo-hor {
	width: 250px;
	vertical-align: middle;
}

ul {
	list-style: none;
}

ul li::before {
	content: "\2022";
	color: var(--greenAccessible);
	display: inline-block;
	width: 1em;
	margin-left: -1em;
}

/* TEXT */
h1 {
	color: var(--purpleTelus);
	font-family: "HelveticaNow500normal";
}

h5 {
	color: var(--greenAccessible);
	font-family: "HelveticaNow400normal";
	margin-bottom: 0;
}

h6 {
	color: var(--greyThunder);
	font-family: "HelveticaNow400normal";
}

p,
li {
	font-family: "HelveticaNow300normal";
}

.header-title {
	display: contents;
	vertical-align: middle;
}

.list-indent {
	margin-left: 2rem;
}

.text-big-bold {
	font-size: 1.3em;
	text-transform: uppercase;
}

.text-help {
	font-size: 0.8em;
	color: var(--greyShuttle);
	font-style: italic;
}

.text-invalid {
	font-size: 0.95em;
	color: var(--red) !important;
	font-family: "HelveticaNow400normal";
	margin-bottom: 0;
}

.text-tight {
	line-height: 1.25em;
}

.text-warning {
	color: var(--red) !important;
	font-family: "HelveticaNow500normal";
}

.tooltip-arrow {
	color: var(--purpleEminence);
}

.tooltip-inner {
	max-width: 300px;
	background-color: var(--purpleEminence);
}

/* UTILITIES */
hr {
	color: var(--greenAccessible);
	margin: 15px 0;
}

input:focus {
	border-color: var(--purpleEminence) !important;
	box-shadow: 0 0 6px var(--purpleEminence) !important;
}

textarea:focus {
	border-color: var(--purpleEminence) !important;
	box-shadow: 0 0 6px var(--purpleEminence) !important;
}

.form-check-input:hover {
	box-shadow: 0 0 3px var(--purpleEminence) !important;
}

.form-check-input:active {
	background-color: var(--transparent);
	border: 2px solid var(--purpleEminence);
}

.form-check-input:checked {
	background-color: var(--purpleEminence);
	border: 1px solid var(--purpleEminence);
}

.form-label {
	margin-top: 0.75rem;
	margin-bottom: 0.25rem !important;
}

.form-select:focus {
	border-color: var(--purpleEminence) !important;
	box-shadow: 0 0 6px var(--purpleEminence) !important;
}

.link-inline {
	display: contents;
}

.login-icon-inline {
	width: auto;
	padding-top: 1rem;
}


