/* 	BRAND STYLES */
:root {
	--amber: #faca69;
	--amberDark: #b4872c;
	--amberLight: #fff9ee;
	--bgModal: rgba(244, 244, 247, 0.97);
	--black: #000000;
	--dark10: rgba(0, 0, 0, 0.1);
	--dark20: rgba(0, 0, 0, 0.2);
	--dark40: rgba(0, 0, 0, 0.4);
	--dark60: rgba(0, 0, 0, 0.6);
	--dark80: rgba(0, 0, 0, 0.8);
	--dark97: rgba(0, 0, 0, 0.97);
	--greenAccessible: #2b8000;
	--greenDarkFern: #163e06;
	--greenPanache: #f4f9f2;
	--greenSanFelix: #1f5c09;
	--greenTelus: #66cc00;
	--greenTusk: #e3f6d1;
	--greenYellow: #bfe797;
	--greyAlabaster: #fafafa;
	--greyAthens: #f4f4f7;
	--greyCharcoal: #414547;
	--greyCloud: #b2b9bf;
	--greyMystic: #e3e6e8;
	--greyShuttle: #676e73;
	--greyThunder: #2c2e30;
	--light10: rgba(255, 255, 255, 0.1);
	--light20: rgba(255, 255, 255, 0.2);
	--light40: rgba(255, 255, 255, 0.4);
	--light60: rgba(255, 255, 255, 0.6);
	--light80: rgba(255, 255, 255, 0.8);
	--light97: rgba(255, 255, 255, 0.97);
	--purpleDark: #3f2a54;
	--purpleDeluge: #7c53a5;
	--purpleEminence: #613889;
	--purpleTelus: #4b286d;
	--red: #e12339;
	--redDark: #c12335;
	--redDarker: #560c0c;
	--redLight: #fff6f8;
	--transparent: rgba(0, 0, 0, 0);
	--white: #ffffff;
}
